import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import { FiFacebook } from "react-icons/fi";

const websiteInfoUrl = "https://wealthcastledubai.com/smartend/api/v1/website/info";
const socialLinksUrl = "https://wealthcastledubai.com/smartend/api/v1/website/social";

const Footer = () => {
  const [websiteInfo, setWebsiteInfo] = useState({});
  const [socialLinks, setSocialLinks] = useState({});

  useEffect(() => {
    const fetchWebsiteInfo = async () => {
      try {
        const response = await fetch(websiteInfoUrl);
        const data = await response.json();
        setWebsiteInfo(data.details);
      } catch (error) {
        console.error("Error fetching website info:", error);
      }
    };

    const fetchSocialLinks = async () => {
      try {
        const response = await fetch(socialLinksUrl);
        const data = await response.json();
        setSocialLinks(data.details);
      } catch (error) {
        console.error("Error fetching social links:", error);
      }
    };

    fetchWebsiteInfo();
    fetchSocialLinks();
  }, []);

  return (
    <div className="text-slate-200">
      <footer className="px-4 py-8 ">
        <div className="flex flex-col md:flex-row justify-between gap-8">
          {/* Logo and Description */}
          <div className="flex-1 flex flex-col items-center md:items-start">
            <Link to="/" className="flex items-center gap-x-1 mb-4">
              <img
                src="https://i.imgur.com/OdqOtTF.png"
                alt="logo"
                className="h-12 w-auto object-contain"
              />
              <h1 className="text-lg">{websiteInfo.site_title || "Wealth Castle"}</h1>
            </Link>
            <p className="text-center md:text-left text-sm">
              {websiteInfo.site_desc || "Loading description..."}
            </p>
            <div className="flex mt-4 gap-5">
              {socialLinks.facebook && (
                <a href={socialLinks.facebook} target="_blank" rel="noopener noreferrer">
                  <FiFacebook className="text-xl hover:text-gray-400" />
                </a>
              )}
              {socialLinks.twitter && (
                <a href={socialLinks.twitter} target="_blank" rel="noopener noreferrer">
                  <FaTwitter className="text-xl hover:text-gray-400" />
                </a>
              )}
              {socialLinks.instagram && (
                <a href={socialLinks.instagram} target="_blank" rel="noopener noreferrer">
                  <FaInstagram className="text-xl hover:text-gray-400" />
                </a>
              )}
              {socialLinks.linkedin && (
                <a href={socialLinks.linkedin} target="_blank" rel="noopener noreferrer">
                  <FaLinkedin className="text-xl hover:text-gray-400" />
                </a>
              )}
            </div>
          </div>

          {/* Quick Links */}
          <div className="flex-1">
            <h2 className="text-lg font-semibold mb-4 text-center md:text-left">Quick Links</h2>
            <ul className="space-y-3 text-center md:text-left">
              <li><Link to="/about-us">About Us</Link></li>
              <li><Link to="/services">Services</Link></li>
              <li><Link to="/blog">Blog</Link></li>
              <li><Link to="/property">Property</Link></li>
              <li><Link to="/faqs">FAQs</Link></li>
            </ul>
          </div>

          {/* Business Links */}
          <div className="flex-1">
            <h2 className="text-lg font-semibold mb-4 text-center md:text-left">Business</h2>
            <ul className="space-y-3 text-center md:text-left">
              <li><Link to="/terms-and-conditions">Terms & Conditions</Link></li>
              <li><Link to="/privacy-and-policy">Privacy Policy</Link></li>
            </ul>
          </div>
        </div>
      </footer>

      <div className="py-4 text-center  text-sm">
        <p>© 2024 {websiteInfo.site_title || "Wealth Castle"}. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;

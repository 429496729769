import React from "react";
import FaqsList from "../components/faqs/FaqsList";
import { Helmet } from "react-helmet";

const Faqs = () => {
  return (
    <div className="pt-20 px-[3%] md:px-[6%] flex flex-col items-center">
      <Helmet>
        <title>Frequently Asked Questions - Wealth Castle Dubai</title>
        <meta
          name="description"
          content="Find answers to common questions about Wealth Castle Dubai's properties, services, and the real estate market in Dubai. Get informed and make better decisions."
        />
        <meta
          name="keywords"
          content="FAQs, frequently asked questions, Wealth Castle Dubai, property inquiries, Dubai real estate, real estate questions"
        />
      </Helmet>
      <div className="w-[70%] max-w-5xl flex justify-center">
        <FaqsList />
      </div>
    </div>
  );
};

export default Faqs;

import React, { useState, useEffect , useRef} from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import DOMPurify from 'dompurify';
import { Helmet } from "react-helmet"; 
import Popup from "../components/common/Popup";

import { FaChevronLeft, FaChevronRight } from "react-icons/fa";


const ProductPreviews = ({ previews }) => {
  const [index, setIndex] = useState(0);
  const thumbnailContainerRef = useRef(null);

  // Scroll function for left/right buttons
  const scrollThumbnails = (direction) => {
    if (thumbnailContainerRef.current) {
      const scrollAmount = 100; // Adjust for desired scroll amount
      thumbnailContainerRef.current.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="rounded-xl p-4 sm:p-6 lg:p-8">
      <div className="text-center mb-4 md:p-6">
        <div className="h-80 overflow-hidden rounded-lg shadow-md">
          <img
            src={previews[index].previewUrl}
            alt="Preview"
            className="w-full h-full object-fill transition-transform duration-300 hover:scale-105"
          />
        </div>
      </div>

      {/* Container with left and right buttons */}
      <div className="relative flex items-center">
        {/* Left Scroll Button */}
        <button
          onClick={() => scrollThumbnails("left")}
          className="absolute left-2 z-10 p-1.5 bg-primary text-white rounded-full"
          style={{ display: previews.length > 1 ? "block" : "none" }}
        >
          <FaChevronLeft />
        </button>

        {/* Scrollable thumbnail container */}
        <div
          ref={thumbnailContainerRef}
          className="flex overflow-x-auto gap-3 pb-2 w-full lg:scrollbar-hide md:scrollbar-hide scrollbar-hide"
        >
          <ul className="flex flex-nowrap gap-3">
            {previews.map((preview, i) => (
              <li
                className={`w-20 h-20 flex-shrink-0 flex justify-center items-center p-1 rounded-md border ${
                  index === i ? "border-primary" : "border-gray-300 dark:border-slate-600"
                } cursor-pointer transition-transform duration-200 transform hover:scale-110`}
                key={i}
                onClick={() => setIndex(i)}
              >
                <img
                  src={preview.thumbUrl}
                  alt="Thumbnail"
                  className="w-full h-full object-cover rounded-md"
                />
              </li>
            ))}
          </ul>
        </div>

        {/* Right Scroll Button */}
        <button
          onClick={() => scrollThumbnails("right")}
          className="absolute right-2 z-10 p-1.5 bg-primary text-white rounded-full"
          style={{ display: previews.length > 1 ? "block" : "none" }}
        >
          <FaChevronRight />
        </button>
      </div>
    </div>
  );
};


ProductPreviews.propTypes = {
  previews: PropTypes.array.isRequired,
};

const PropertyDescription = () => {
  const { url_slug } = useParams(); // Get the dynamic URL slug from the URL
  const [product, setProduct] = useState(null);
  const navigate = useNavigate(); 
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleRedirect = () => {
    navigate(`/contact/${url_slug}`); // Redirect to the /contact page
  };

  const handleBrochureClick = () => {
    if (product.attach_file) {
      window.open(product.attach_file, "_blank"); // Open brochure in a new tab
    }
  };
  
  const openPopup = () => {
    setIsPopupOpen(true); // Open popup
  };

  const closePopup = () => {
    setIsPopupOpen(false); // Close popup
  };

  const handleSubmitForm = (formData) => {
    console.log("Form submitted with data: ", formData);
  };

  // Function to format the price like "1,2,70,000"
  const formatPrice = (num) => {
    const str = num.toString();
    const n = str.length;

    if (n > 3) {
      // First comma is placed before the last three digits
      const lastThree = str.substring(n - 3);

      // Remaining digits are processed in groups of two
      const remaining = str.substring(0, n - 3);
      
      // Add commas every 2 digits from the right side for the remaining part
      const formattedRemaining = remaining.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
      
      return formattedRemaining + "," + lastThree;
    }
    
    return str; // If number is less than 1000, return as is
  };

  const getPrice = (fields) => {
    const priceField = fields.find(field => field.title === "Price");
    const price = priceField ? priceField.value : "N/A";
    return price !== "N/A" && !isNaN(price) ? formatPrice(price) : price;
  };

  useEffect(() => {
    const fetchProductData = async () => {
      const response = await fetch(`https://wealthcastledubai.com/smartend/api/v1/topicnew/${url_slug}`);
      const data = await response.json();
      setProduct(data.topic[0]); // Assuming we are interested in the first topic
    };

    fetchProductData();
  }, [url_slug]); // Add url_slug to the dependency array to refetch on change

  if (!product) {
    return <div className="text-center py-10">Loading...</div>; // Handle loading state
  }

  // Prepare previews for the ProductPreviews component
  const previews = [];

  // Check for primary photo_file
  if (product.photo_file) {
    previews.push({
      previewUrl: product.photo_file,
      thumbUrl: product.photo_file,
    });
  }

  // Add all photos from the photos array
  product.photos.forEach(photo => {
    previews.push({
      previewUrl: photo.url,
      thumbUrl: photo.url,
    });
  });

  // Add images from Joined_categories
  // product.Joined_categories.forEach(category => {
  //   if (category.photo) {
  //     previews.push({
  //       previewUrl: category.photo,
  //       thumbUrl: category.photo,
  //     });
  //   }
  // });

  // If no images are found, use the default image
  if (previews.length === 0) {
    previews.push({
      previewUrl: "https://wealthcastledubai.com/smartend/uploads/topics/default.png",
      thumbUrl: "https://wealthcastledubai.com/smartend/uploads/topics/default.png",
    });
  }

  const limitedPreviews = previews;
  // const limitedPreviews = previews.slice(0, 6);

  // Sanitize product details and remove unwanted styles
  const sanitizedDetails = DOMPurify.sanitize(product.details, {
    FORBID_ATTR: ['style'], // Forbid inline styles
  });

  const stripHtmlTags = (htmlString) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlString;
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  return (
    <section className="py-14 md:py-24 text-zinc-900 dark:text-white relative overflow-hidden z-10">
      <Helmet>
        <title>{product.title}</title>
        <meta
          name="description"
          content={stripHtmlTags(sanitizedDetails) || "Product content goes here..."}
        />
        <meta
          name="keywords"
          content="properties, real estate, Wealth Castle Dubai, property listings, property search, Dubai real estate"
        />
      </Helmet>

      <div className="container px-4 mx-auto">
        <div className="flex flex-col lg:flex-row gap-8">
          <div className="w-full lg:w-1/2">
            <ProductPreviews previews={limitedPreviews} />
          </div>
          <div className="w-full lg:w-1/2 mt-8">
            <div className="mb-6 lg:mb-12 p-6">
              <h1 className="text-3xl leading-none md:text-4xl font-semibold mb-4">
                {product.title}
              </h1>
              <p className="opacity-70 mb-4 flex gap-4">
                <span className="text-primary font-bold">
                  {product.Joined_categories[0]?.title || 'N/A'}
                </span>
                <span className="text-primary font-bold">
                  {product.location.name || 'N/A'}
                </span>
              </p>
              <p className="text-xl font-semibold mb-8 text-primary">
            <span className="text-black dark:text-white">Starting From</span>&nbsp; AED {getPrice(product.fields)}
              </p>

              <p className="mb-4 text-zinc-900 dark:text-white" dangerouslySetInnerHTML={{ __html: sanitizedDetails || "Blog content goes here..." }} />

              <div className="flex gap-3 mb-8">
                <button onClick={openPopup} className="btn btn-primary text-white font-bold py-2 px-4 rounded transition-colors duration-300">
                  Send Enquiry
                </button>

                {product.attach_file && (
                  <button onClick={handleBrochureClick} className="btn btn-primary text-white font-bold py-2 px-4 rounded transition-colors duration-300">
                    View Brochure
                  </button>
                )}
              </div>

            </div>
          </div>
        </div>
      </div>
      <Popup isOpen={isPopupOpen} onClose={closePopup} onSubmit={handleSubmitForm} />
    </section>
  );
};

export default PropertyDescription;

import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import axios from "axios";
import { useParams } from "react-router-dom";
import confetti from 'canvas-confetti'; // Import canvas-confetti

const Popup = ({ isOpen, onClose }) => {
  const { url_slug } = useParams();

  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactSubject, setContactSubject] = useState("");
  const [contactMessage, setContactMessage] = useState("Hi, I would like to get more information about this property.");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const formatUrlSlug = (slug) => {
    return slug
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  useEffect(() => {
    if (url_slug) {
      setContactSubject(formatUrlSlug(url_slug));
    }
  }, [url_slug]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    if (contactPhone.length < 10) {
      setError("Phone number must be at least 10 digits.");
      setLoading(false);
      return;
    }

    const payload = {
      api_key: "402784613679330",
      topic_id: 8,
      contact_name: contactName,
      contact_email: contactEmail,
      contact_phone: contactPhone,
      contact_subject: contactSubject,
      contact_message: contactMessage,
    };

    try {
      await axios.post("https://wealthcastledubai.com/smartend/api/v1/contact", payload);
      setSuccess("Your message has been sent successfully!");
      setContactName("");
      setContactEmail("");
      setContactPhone("");
      setContactMessage("");

      // Trigger confetti on success
      triggerConfetti();

      // Smoothly close the popup after 2 seconds
      setTimeout(() => {
        handleClose();
      }, 2000); // 2-second delay before closing
    } catch (err) {
      setError("Error sending message. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/\D/g, "");
    setContactPhone(numericValue);
  };

  const handleClose = () => {
    // Reset the success and error messages when closing the popup
    setSuccess(null);
    setError(null);
    onClose();
  };

  const triggerConfetti = () => {
    const end = Date.now() + 3 * 1000; // Show confetti for 3 seconds

    const frame = () => {
      confetti({
        particleCount: 2, // Increased particle count for more effect
        spread: 350,         // Adjust spread for better distribution
        startVelocity: 25,   // Higher initial velocity for a more dynamic effect
        decay: 0.92,         // Slightly slower decay for prolonged visibility
        gravity: 0.4,        // Adjust gravity to make it fall more slowly
        origin: {
          x: Math.random(), // Random x-origin for a scattered effect
          y: 0              // Start from the top
        },
        zIndex: 9999,       // Ensure confetti appears above everything
      });

      if (Date.now() < end) {
        requestAnimationFrame(frame);
      }
    };

    frame(); // Start the confetti burst animation
  };


  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 mt-[4%]">
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg relative max-w-md w-full overflow-y-auto" style={{ maxHeight: '90vh' }}>
        <button
          className="absolute top-2 right-2 border-2 border-gray-200 w-8 h-8 rounded-full text-gray-400 hover:text-gray-600 dark:text-gray-300"
          onClick={handleClose}
        >
          &times;
        </button>
        <h2 className="text-xl font-bold mb-4">Send Enquiry</h2>
        <form onSubmit={handleSubmit} className="space-y-1">
          <div>
            <label className="block mb-1 text-sm">Name</label>
            <input
              type="text"
              className="w-full p-2 border rounded-lg text-sm bg-white dark:bg-gray-700 dark:text-white text-black" // Explicit text color
              placeholder="Enter your name"
              value={contactName}
              onChange={(e) => setContactName(e.target.value)}
              required
            />
          </div>
          <div>
            <label className="block mb-1 text-sm">Phone Number</label>
            <input
              type="tel"
              className="w-full p-2 border rounded-lg text-sm bg-white dark:bg-gray-700 dark:text-white text-black" // Explicit text color
              placeholder="Enter your phone number"
              value={contactPhone}
              onChange={handlePhoneChange}
              required
            />
          </div>
          <div>
            <label className="block mb-1 text-sm">Email Address</label>
            <input
              type="email"
              className="w-full p-2 border rounded-lg text-sm bg-white dark:bg-gray-700 dark:text-white text-black" // Explicit text color
              placeholder="Enter your email"
              value={contactEmail}
              onChange={(e) => setContactEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <label className="block mb-1 text-sm">Project Name</label>
            <input
              type="text"
              className="w-full p-2 border rounded-lg text-sm bg-white dark:bg-gray-700 dark:text-white text-black" // Explicit text color
              placeholder="Enter the project name"
              value={contactSubject}
              onChange={(e) => setContactSubject(e.target.value)}
              required
            />
          </div>
          <div>
            <label className="block mb-1 text-sm">Message</label>
            <textarea
              className="w-full p-2 border rounded-lg text-sm bg-white dark:bg-gray-700 dark:text-white text-black" // Explicit text color
              placeholder="Enter your message"
              rows="4"
              value={contactMessage}
              onChange={(e) => setContactMessage(e.target.value)}
              required
            ></textarea>
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="btn btn-primary text-white font-bold py-2 px-4 rounded"
              disabled={loading}
            >
              {loading ? "Submitting..." : "Submit"}
            </button>
          </div>
          {error && <p className="text-red-500">{error}</p>}
          {success && <p className="text-green-500">{success}</p>}
        </form>
      </div>
    </div>
  );
};

Popup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Popup;

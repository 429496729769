import { useState, useEffect } from "react";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";

const QuoteWithImage = () => {
  const [quoteData, setQuoteData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        const response = await fetch("https://wealthcastledubai.com/smartend/api/v1/topic/143");
        const data = await response.json();
        if (data && data.topic && data.topic.length > 0) {
          setQuoteData(data.topic[0]); // Store the first topic data
        } else {
          setError("No data found");
        }
        setLoading(false);
      } catch (err) {
        setError("Error fetching data");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Loading and Error States
  if (loading) {
    return <div className="text-center text-gray-600">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500">{error}</div>;
  }

  const isMediumScreen = (windowWidth >= 768 && windowWidth <= 884) || (windowWidth >= 1024 && windowWidth <= 1104);

  return (
    <section className="py-10  px-3 lg:px-0">
      <div className="flex flex-wrap gap-8 lg:gap-12">
        {/* Image Section */}
        <figure className="flex-1 basis-[18rem]">
          <img
            alt={quoteData.title}
            src={quoteData.photo_file || "https://wealthcastledubai.com/smartend/uploads/topics/default.png"}
            className="object-fill w-full rounded-lg"
          />
          <figcaption className="sr-only">{quoteData.title}</figcaption>
        </figure>

        {/* Quote Section */}
        <blockquote className="relative flex-1 basis-full lg:basis-[22rem] lg:flex lg:justify-center lg:items-center">
          <div className="w-full text-left">
            <h1 className="text-2xl sm:text-4xl font-semibold text-black dark:text-white leading-tight">
              <FaQuoteLeft className="inline-block text-3xl md:text-4xl mr-3 text-gray-500" /> {/* Left quote icon */}
              {quoteData.details.replace(/<[^>]+>/g, "")}
              <FaQuoteRight className="inline-block text-3xl md:text-4xl ml-3 text-gray-500" /> {/* Right quote icon */}
            </h1>

            <footer className="mt-3 text-lg font-medium text-gray-500">
              - {quoteData.title}
            </footer>
          </div>
        </blockquote>
      </div>
    </section>
  );
};

export default QuoteWithImage;

import ReactPaginate from "react-paginate";
import { FiChevronsLeft, FiChevronsRight } from "react-icons/fi";

const Pagination = ({ itemsPerPage, totalItems, currentPage, onPageChange }) => {
  const pageCount = Math.ceil(totalItems / itemsPerPage);

  const handlePageClick = (e) => {
    onPageChange(e.selected + 1);  
  };

  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel={<FiChevronsRight />}
      onPageChange={handlePageClick}
      pageRangeDisplayed={2}
      pageCount={pageCount}
      forcePage={currentPage - 1} 
      previousLabel={<FiChevronsLeft />}
      renderOnZeroPageCount={null}
      containerClassName="wb-pagination"
      pageClassName="pagination-item"
      pageLinkClassName="pagination-link"
      activeClassName="pagination-link-active"
      previousLinkClassName="prev"
      nextLinkClassName="next"
      disabledClassName="disabled"
    />
  );
};

export default Pagination;

import { json, Link } from "react-router-dom";
import projectsData from "../../../utils/apis/projects.json"; // Import the JSON file
import { useEffect, useState } from "react";
// import { FaLocationDot } from "react-icons/fa6";

import axios from "axios";
import { BiMap } from "react-icons/bi";



const SkeletonCard = () => {
  return (
    <div className="relative w-full group">
      <div className="overflow-hidden rounded-md">
        <div className="w-full h-[250px] bg-gray-200 animate-pulse" />
      </div>
      <div className="absolute bottom-0 left-0 w-full px-2 py-2 bg-gradient-to-t from-black/80 text-slate-100 to-transparent">
        <div className="w-3/4 h-4 bg-gray-200 animate-pulse mb-2" />
        <div className="flex justify-between items-center">
          <div className="w-1/4 h-4 bg-gray-200 animate-pulse" />
          <div className="flex items-center space-x-1 text-sm">
            <div className="w-4 h-4 bg-gray-200 animate-pulse rounded-full" />
            <div className="w-1/2 h-4 bg-gray-200 animate-pulse" />
          </div>
        </div>
      </div>
    </div>
  );
};

const Projects = () => {
  const { projects, heading } = projectsData;
  const [propertyData, setPropertyData] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true); // Destructure the projects from the JSON data

  useEffect(() => {
    const fetchProperties = async () => {
      setLoading(true);
      try {
        // Prepare the request body based on the provided parameters

        // Hit the product filter API with dynamic params
        const response = await axios.post(
          `https://wealthcastledubai.com/smartend/api/v1/product-filter`
        );

        // Assuming the API response structure based on the provided response
        setPropertyData(response.data.topics || []);

        // console.log(response.data.topics, "this  is property data");
      } catch (error) {
        console.error("Error fetching properties:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProperties();
  }, []);

  return (

<>
{loading? (<SkeletonCard/> ): (  <div className="pt-10 pb-16">
      <div className="text-center">
        <h1 className="mx-auto sub-heading">{heading.title}</h1>
        <h1 className="heading">{heading.subtitle}</h1>
      </div>
      <div className="grid grid-cols-1 gap-4 mt-8 sm:grid-cols-2 md:grid-cols-3">
        {propertyData
          .slice(0, 6)
          .map(
            ({
              url_slug,
              id,
              name,
              title,
              number,
              photo_file,
              Joined_categories,
              location,
              
            }) => (
              <Link
                key={id}
                to={`/property/${Joined_categories[0].url_slug
                  .toLowerCase()
                  .replace(/\s+/g, "-")}/${url_slug}`}
              >
                <div className="relative w-full group">
                  <div className="overflow-hidden rounded-md">
                    <span className="!opacity-100 ">
                      <img
                        src={
                          photo_file ||
                          Joined_categories[0].photo ||
                          "https://wealthcastledubai.com/smartend/uploads/topics/default.png"
                        }
                        alt={name}
                        className="w-full h-fit md:h-[250px] object-cover group-hover:scale-125 transition-a"
                      />
                    </span>
                  </div>

                  <div className="absolute bottom-0 left-0 w-full px-2 py-2 transition-transform bg-gradient-to-t from-black/80 text-slate-100 to-transparent">
                    <h1 className="text-lg font-semibold">{title}</h1>
                    <div className="flex justify-between items-center py-2">
                      <span className="text-sm font-semibold">
                        {Joined_categories[0].title}
                      </span>
                      <span className="flex items-center space-x-1 text-sm">
                        <BiMap className="inline-block text-lg" />
                        <span>{location.name}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            )
          )}
      </div>
    </div>) }



  


    </>
  );
};

export default Projects;

import React, { useState, useEffect } from "react";

const CompanyPhilosophy = () => {
  const [homeWelcomeTopic, setHomeWelcomeTopic] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch data from the API
    fetch("https://wealthcastledubai.com/smartend/api/v1/topics/1/page/1/count/10")
      .then((response) => response.json())
      .then((data) => {
        const topics = data.topics;
        // Find the topic with id: 5 and title: "Home Welcome"
        const topic = topics.find((item) => item.id === 5);
        if (topic) {
          setHomeWelcomeTopic(topic);
        } else {
          setError("Topic not found");
        }
        setLoading(false);
      })
      .catch((error) => {
        setError("Error fetching data");
        setLoading(false);
      });
  }, []);

  // Skeleton Loader
  if (loading) {
    return (
      <section className="text-gray-600 body-font">
        <div className="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
          {/* Image skeleton */}
          <div className="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded bg-gray-300 animate-pulse h-[300px]" />
          {/* Text skeleton */}
          <div className="lg:w-2/3 w-full text-center">
            <div className="h-10 bg-gray-300 rounded-md mb-4 animate-pulse" />
            <div className="h-6 bg-gray-300 rounded-md mb-4 animate-pulse w-3/4 mx-auto" />
            <div className="h-6 bg-gray-300 rounded-md mb-4 animate-pulse w-2/3 mx-auto" />
            <div className="h-6 bg-gray-300 rounded-md mb-4 animate-pulse w-1/2 mx-auto" />
          </div>
        </div>
      </section>
    );
  }

  // Error handling
  if (error) return <div>{error}</div>;

  // Main content after data is loaded
  return (
    <div>
      <section className="text-gray-600 body-font">
        <div className="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
          {/* Loaded Image */}
          <img
            className="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded"
            alt="hero"
            src={homeWelcomeTopic.photo_file || "https://wealthcastledubai.com/smartend/uploads/topics/default.png"}
          />
          <div className="text-center lg:w-2/3 w-full">
            {/* Loaded Title */}
            <h1
              className="text-4xl font-bold capitalize md:text-5xl text-black dark:text-white"
              dangerouslySetInnerHTML={{ __html: homeWelcomeTopic.title }}
            />
            {/* Loaded Details */}
            <p
              dangerouslySetInnerHTML={{
                __html: homeWelcomeTopic.details,
              }}
              className="text-black dark:text-white mt-[5%] text-left"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default CompanyPhilosophy;

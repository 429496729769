import React, { useEffect } from "react";

const ReusablePopup = ({ isOpen, onClose, children }) => {
  // Effect to handle body overflow and z-index
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"; // Prevent background scrolling
      // Add a class to the body to set all z-index to 0
      document.body.classList.add("popup-open");
    } else {
      document.body.style.overflow = "auto"; // Restore background scrolling
      document.body.classList.remove("popup-open");
    }

    // Cleanup function to reset styles when the component unmounts
    return () => {
      document.body.style.overflow = "auto";
      document.body.classList.remove("popup-open");
    };
  }, [isOpen]);

  if (!isOpen) return null; // Don't render anything if the popup is closed

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Background overlay */}
      <div
        className="absolute inset-0 bg-black bg-opacity-50"
        onClick={onClose}
      ></div>

      {/* Popup Content */}
      <div className="relative bg-white dark:bg-gray-800 rounded-lg shadow-lg w-full max-w-lg md:max-w-2xl lg:max-w-4xl mx-auto z-[1001] transition-all duration-300">
        {/* Close Button */}
        <button
          className="absolute top-2 right-2 text-gray-600 dark:text-gray-400"
          onClick={onClose}
        >
          ✖
        </button>

        {/* Content Area */}
        <div className="p-8 max-h-[80vh] overflow-y-auto">
          {children}
        </div>
      </div>
    </div>
  );
};

export default ReusablePopup;

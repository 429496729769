import { Pagination } from "../components/common/page-componets";
// import { PropertyList } from "../components/property";
import { useEffect, useState } from "react";
import axios from "axios"; // Axios for API requests
import FilteredProperty from "../components/property/property-4/FilteredProperty";
import { useLocation } from "react-router-dom";

const PropertyTwo = () => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  
  // Get query parameters
  const searchParams = new URLSearchParams(location.search);
  const location_id = searchParams.get("location_id");
  const price = searchParams.get("price");
  const category = searchParams.get("category");

  useEffect(() => {
    const fetchProperties = async () => {
      setLoading(true);
      try {
        // Hit the product filter API with dynamic params
        const response = await axios.post(
          `https://wealthcastledubai.com/smartend/api/v1/product-filter`,
          { location_id, price, category }
        );

        // Assuming the API response structure based on the provided response
        setProperties(response.data.topics || []);
      } catch (error) {
        console.error("Error fetching properties:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProperties();
  }, [location_id, price, category]);

  return (
    <div className="pt-20 px-[3%] md:px-[6%]">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <FilteredProperty properties={properties} />
          <Pagination itemsPerPage={9} pageData={properties} />
        </>
      )}
    </div>
  );
};

export default PropertyTwo;

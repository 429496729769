import React, { useState, useEffect } from "react";
import { ContactInfo, Form, Map } from "../components/contact";
import axios from "axios";
import { Helmet } from "react-helmet";

const Contact = () => {
  const [contactDetails, setContactDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchContactDetails = async () => {
      try {
        const response = await axios.get("https://wealthcastledubai.com/smartend/api/v1/website/contacts");
        setContactDetails(response.data.details);
      } catch (error) {
        setError("Error fetching contact details");
      } finally {
        setLoading(false);
      }
    };

    fetchContactDetails();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="pt-20 px-[3%] md:px-[6%]">
      <Helmet>
        <title>Contact Us - Wealth Castle Dubai</title>
        <meta
          name="description"
          content="Get in touch with Wealth Castle Dubai for property inquiries, partnerships, or general questions. We're here to help you with any real estate-related needs in Dubai."
        />
        <meta
          name="keywords"
          content="contact us, Wealth Castle Dubai, property inquiries, real estate contact, Dubai real estate, contact details"
        />
      </Helmet>
      <div>
        <h1 className=" text-primary text-4xl font-bold text-center mt-[2%]">Get in touch with us</h1>
       
      </div>
      <Form />
      {/* {contactDetails && <ContactInfo details={contactDetails} />} */}
      {/* <Map /> */}
    </div>
  );
};

export default Contact;

import { useState, useEffect } from "react";
import { FiCheck, FiLayers, FiUsers } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import specialityData from "../../../utils/apis/speciality.json";

const Speciality = () => {
  const navigate = useNavigate(); 
  const [aboutTopic, setAboutTopic] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const maxLength = 700; // Set the max length for the details

  useEffect(() => {
    // Fetch data from the API
    fetch('https://wealthcastledubai.com/smartend/api/v1/topic/126')
      .then((response) => response.json())
      .then((data) => {
        const topic = data.topic[0]; // Access the first topic in the array
        if (topic) {
          setAboutTopic(topic);
          setData(specialityData); // Assuming this is for the static part
        } else {
          setError('Topic not found');
        }
        setLoading(false);
      })
      .catch((error) => {
        setError('Error fetching data');
        setLoading(false);
      });
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  const iconComponents = {
    FiCheck: <FiCheck />,
    FiLayers: <FiLayers />,
    FiUsers: <FiUsers />
  };

  // Function to handle button click
  const handleReadMore = () => {
    navigate("/about-us"); // Redirect to the /about page
  };

  // Helper function to truncate text if it's more than 200 characters
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  // Helper function to render the details without <li> elements and apply truncation
  const renderDetailsWithoutListItems = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    
    // Remove all <li> elements from the content
    doc.querySelectorAll('li').forEach((li) => li.remove());
    
    const truncatedText = truncateText(doc.body.innerText, maxLength); // Truncate the text

    return <div>{truncatedText}</div>;
  };

  return (
    <div className="py-16 "> {/* Reduced padding for md */}
      <div className="flex flex-wrap gap-10">
        <div className="flex-1 basis-[20rem]">
          <h1 className="sub-heading">{data.sectionHeading}</h1>
          <h1 className="heading">{aboutTopic.title}</h1>
          <div className="mt-3">
            {/* Render truncated details */}
            {renderDetailsWithoutListItems(aboutTopic.details)}
          </div>
          <div className="mt-4">
            <button className="mt-4 btn btn-primary" onClick={handleReadMore}>
              {data.buttonText}
            </button>
          </div>
        </div>
        <div className="flex-1 basis-[20rem]">
          <div className="relative">
            <img
              src={aboutTopic.photo_file || aboutTopic.photos[0].url || "https://wealthcastledubai.com/smartend/uploads/topics/default.png"}
              alt={data.image.alt}
              className="rounded-lg w-full sm:h-[400px] object-fill"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Speciality;

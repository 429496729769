import React from "react";

const SkeletonCard = () => {
  return (
    <div className="shadow-light dark:border-card-dark border rounded-lg overflow-hidden relative group basis-[18rem] animate-pulse">
      <div className="group !opacity-100 overflow-hidden relative">
        <div className="w-full h-fit md:h-[250px] bg-gray-300"></div>
        <div className="absolute bottom-0 left-0 w-full px-2 py-2 transition-transform bg-gradient-to-t from-black/80 sm:translate-y-10 group-hover:translate-y-0 to-transparent">
          <div className="text-white flex-align-center gap-x-2">
            <div className="h-4 w-24 bg-gray-300 rounded"></div>
          </div>
        </div>
      </div>
      <div className="p-3">
        <div className="h-4 w-full bg-gray-300 rounded mb-2"></div>
        <div className="flex justify-between mt-3">
          <div className="flex-align-center gap-x-2">
            <div className="h-4 w-16 bg-gray-300 rounded"></div>
          </div>
          <div className="flex-align-center gap-x-2">
            <div className="h-4 w-16 bg-gray-300 rounded"></div>
          </div>
        </div>
        <div className="mt-4 flex-center-between">
          <div className="h-6 w-24 bg-gray-300 rounded"></div>
          <div className="h-8 w-24 bg-gray-300 rounded"></div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonCard;

import { useEffect, useState } from "react";
import feedsData from "../../../utils/apis/feedsData.json";
import SingleFeedCardGrid from "../../common/page-componets/SingleFeedCardGrid";

const Feeds = () => {
  // Use JSON data for initial values
  const { heading: defaultHeading, subheading: defaultSubheading, feeds: defaultFeeds } = feedsData;
  const [feed, setFeed] = useState(defaultFeeds); // Initialize with JSON data
  const [sectionTitle, setSectionTitle] = useState(defaultSubheading); // Initialize with JSON subheading
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFeeds = async () => {
      try {
        const response = await fetch(
          "https://wealthcastledubai.com/smartend/api/v1/topics/3/page/1/count/10"
        );
        const data = await response.json();

        if (data && data.topics) {
          setFeed(data.topics); // Update feed with API topics
          // console.log(defaultHeading)
          setSectionTitle(data.section_title); // Update section title with API data
        } else {
          setError("No topics found");
        }
      } catch (error) {
        setError("Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchFeeds();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="pt-10 pb-16">
      <div className="text-center">
        <h1 className="mx-auto sub-heading">{sectionTitle}</h1> 
        <h1 className="heading">{defaultSubheading }</h1>
      </div>
      <div className="grid grid-cols-1 gap-4 mt-8 md:grid-cols-2">
        {feed.slice(0,4).map((feedItem) => (
          <SingleFeedCardGrid key={feedItem.id} {...feedItem} />
        ))}
      </div>
    </div>
  );
};

export default Feeds;

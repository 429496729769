import SingleProductCard from "../../common/page-componets/SingleProductCard";

const PropertyList = ({ properties, basis }) => {
  // console.log(properties, "from propertyList");
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
      {properties?.map((property) => (
        <SingleProductCard key={property.id} {...property} basis={basis} />
      ))}
    </div>
  );
};

export default PropertyList;

import { useEffect, useState } from "react";
import TestimonialCard from "./TestimonialCard";

const Testimonial = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await fetch("https://wealthcastledubai.com/smartend/api/v1/topics/11/page/1/count/10");
        const data = await response.json();

        if (data?.topics?.length) {
          setTestimonials(data.topics);
        } else {
          throw new Error(" ");
        }
      } catch (err) {
        setError(err.message || " ");
      } finally {
        setLoading(false);
      }
    };

    fetchTestimonials();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="pt-10 pb-16">
      <div className="text-center">
        <h1 className="mx-auto sub-heading">Testimonials</h1>
      </div>
      <div className="flex flex-wrap gap-4 mt-8">
        {testimonials.map(testimonial => (
          <TestimonialCard key={testimonial.id} {...testimonial} />
        ))}
      </div>
    </div>
  );
};

export default Testimonial;

import axios from "axios";
import {
  AboutUs,
  Brands,
  Feeds,
  Team,
  Testimonial,
} from "../components/common/page-componets";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import CeosMsg from "../components/CeosMsg";
const About = () => {

  const [aboutUs, setAboutUs] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("https://wealthcastledubai.com/smartend/api/v1/topic/126");
        setAboutUs(res.data.topic[0]); // Update to access `details` directly
        // console.log(res.data.topic[0]);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, []);


  return (
    <div className="pt-20 px-[3%] md:px-[6%]">
      <Helmet>
        <title>About Us - {aboutUs?.title || 'About Page'}</title>
        <meta
          name="description"
          content={aboutUs?.details.replace(/(<([^>]+)>)/gi, "") || 'This is the description of my awesome page.'}
        />
      </Helmet>
      <AboutUs />
      <CeosMsg/>
      {/* <Team /> */}
      {/* <Testimonial /> */}
      {/* <Brands /> */}
      {/* <Feeds /> */}
    </div>
  );
};

export default About;

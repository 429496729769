const apiUrl = 'https://wealthcastledubai.com/smartend/api/v1/categories/8';

// Variable to store the fetched data
let categoriesData;

// Variable to store the nav links
export let navLinks = [
  {
    id: 1,
    linkText: "Home",
    url: "/",
  },
  {
    id: 2,
    linkText: "About Us",
    url: "/about-us",
  },
  {
    id: 3,
    linkText: "Services",
    url: "/services",
  },
  {
    id: 4,
    linkText: "Property",
    url: "/property",
    subLinks: [], // Initialize subLinks as an empty array
  },
  {
    id: 5,
    linkText: "Lease Property",
    url: "/blog",
  },
  {
    id: 6,
    linkText: "FAQs",
    url: "/faqs",
  },
  {
    id: 7,
    linkText: "Contact",
    url: "/contact",
  },
];

// Function to update navLinks after fetching data
async function fetchData() {
  try {
    const response = await fetch(apiUrl);

    // Check if the response is successful
    if (!response.ok) {
      throw new Error('Network response was not ok: ' + response.statusText);
    }

    categoriesData = await response.json(); // Await and parse the JSON response

    // Populate the subLinks for the "Property" nav link
    const propertyCategory = categoriesData.categories.find(category => category.title === "Properties");

    if (propertyCategory && propertyCategory.sub_categories) {
      const updatedSubLinks = propertyCategory.sub_categories.map(subCategory => ({
        id: subCategory.id,
        linkText: subCategory.title, // Use title as linkText
        url: `/property?category=${subCategory.id}`, // Use href as url
      }));

      // Set the subLinks into the navLinks array
      navLinks[3].subLinks = updatedSubLinks;
    }

    // console.log(navLinks); // Check the updated navLinks
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error);
  }
}

// Call the function
fetchData();

// Use navLinks here for further operations or pass it to other functions
// setTimeout(() => {
//   // console.log("Updated navLinks after fetching:", navLinks);
// }, 1000);



// import { useSelector } from "react-redux";
import SingleProductCard from "../../common/page-componets/SingleProductCard";

const FilteredProperty = ({ properties }) => {
  console.log(properties, "from propertyList")
  return (
    <div className="flex flex-wrap gap-4">
    {properties?.map((property) => (
      <SingleProductCard key={property.id} {...property} />
    ))}
  </div>
  );
};

export default FilteredProperty;

import {
  Categories,
  Counter,
  Feeds,
  Services as ServicesList,
  Team,
} from "../components/common/page-componets";
import { Helmet } from "react-helmet";

const Services = () => {
  return (
    <div className="pt-20 px-[3%] md:px-[6%]">
      <Helmet>
        <title>Our Services - Wealth Castle Dubai</title>
        <meta
          name="description"
          content="Discover our wide range of services at Wealth Castle Dubai. We offer top-quality solutions for your real estate needs, ensuring satisfaction and professionalism in every project."
        />
        <meta
          name="keywords"
          content="services, real estate, wealth castle dubai, real estate solutions, property management"
        />
      </Helmet>
      {/* <ServicesList /> */}
      <Categories />
      {/* <Counter /> */}
      {/* <Team /> */}
      <Feeds />
    </div>
  );
};

export default Services;

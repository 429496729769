import {
  Brands,
  Counter,
  Featured,
  Projects,
  Services,
  Testimonial,
} from "../components/common/page-componets";
import { Helmet } from "react-helmet";
import ResponsiveBanner from "../components/home/Banners/ResponsiveBanner";
import { CarauselHome } from "../components/home/carausel-1/CarauselHome";
// import CarauselHome from "../components/home/carausel-1/CarauselHome";
import {
  Feeds,
  Filters,
  Hero,
  Invest,
  Speciality,
} from "../components/home/home-1";
import Team from "./Team";
import { useEffect, useState } from "react";
import axios from "axios";
import ServicesWithLogo from "../components/ServicesWithLogo";
import QuoteWithImage from "../components/QuoteWithImage";

const Home = () => {
  const [siteInfo, setSiteInfo] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("https://wealthcastledubai.com/smartend/api/v1/website/info");
        setSiteInfo(res.data.details); // Update to access `details` directly
        // console.log(res.data.details);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, []);
  return (
    <div className="pt-16 px-[3%] md:px-[6%]">
      <Helmet>
        <title>{siteInfo.site_title || 'Home Page'}</title>
        <meta
          name="description"
          content={siteInfo.site_desc || 'This is the description of my home page.'}
        />
        <meta 
          name="keywords" 
          content={siteInfo.site_keywords || 'default, keywords, here'}
        />
      </Helmet>
      <ResponsiveBanner />
      <ServicesWithLogo/>
     
      {/* <CarauselHome /> */}
      <QuoteWithImage/>
      <Hero />
      <Filters />
      {/* <Invest /> */}
      <Speciality />
      {/* <Services /> */}
      {/* <Featured /> */}
      {/* <Counter /> */}
      <Projects />
      <Testimonial />
      {/* <Brands /> */}
      <Team />
      <Feeds />
    </div>
  );
};

export default Home;

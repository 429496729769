import { useEffect, useState } from "react";
import { FiCheck } from "react-icons/fi";

const AboutUs = () => {
  const [aboutUs, setAboutUs] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    // Fetch data from the API
    fetch('https://wealthcastledubai.com/smartend/api/v1/topic/126')
      .then((response) => response.json())
      .then((data) => {
        const topic = data.topic[0]; // Access the first topic in the array
        if (topic) {
          setAboutUs(topic);
        } else {
          setError('About Us data not found');
        }
        setLoading(false);
      })
      .catch((error) => {
        setError('Error fetching About Us data');
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Function to extract text from HTML string
  const extractDetails = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const paragraphs = Array.from(doc.querySelectorAll('p')).map(p => p.textContent);
    const listItems = Array.from(doc.querySelectorAll('li')).map(li => li.textContent);

    return { paragraphs, listItems };
  };

  const renderSkeleton = () => (
    <div className="pt-16 pb-20 animate-pulse">
      <div className="flex flex-wrap gap-24">
        <div className="relative flex-1 basis-[18rem] border">
          <div className="bg-gray-300 w-full h-[300px] rounded-lg"></div>
        </div>
        <div className="relative flex-1 basis-[22rem]">
          <div className="bg-gray-300 h-6 w-28 mb-4"></div>
          <div className="bg-gray-300 h-10 w-52 mb-6"></div>
          <div className="space-y-3">
            <div className="bg-gray-300 h-4 w-full"></div>
            <div className="bg-gray-300 h-4 w-full"></div>
            <div className="bg-gray-300 h-4 w-full"></div>
          </div>
        </div>
      </div>
    </div>
  );

  if (loading) return renderSkeleton();
  if (error) return <div>{error}</div>;

  const { paragraphs, listItems } = extractDetails(aboutUs.details);

  const isMediumScreen = (windowWidth >= 768 && windowWidth <= 884) || (windowWidth >= 1024 && windowWidth <= 1104);


  return (
   <>
    {!isMediumScreen? (
      <div className="pt-16 pb-20 lg:px-0 px-3">
      {/* Flex section for image and first 2 paragraphs */}
      <div className="flex flex-wrap gap-16">
        <div className="relative flex-1 basis-[18rem]">
          <img
            src={aboutUs.photo_file || aboutUs.photos[0].url}
            alt="About Us"
            className="object-cover w-full rounded-lg "
          />
        </div>
        <div className="relative flex-1 basis-[22rem]">
          <h1 className="sub-heading">{"About Us"}</h1>
          <h1 className="heading">{aboutUs.title}</h1>
          {/* Display first two paragraphs */}
          {paragraphs.slice(0, 2).map((paragraph, index) => (
            <p key={index} className="mt-3">
              {paragraph}
            </p>
          ))}
        </div>
      </div>

      {/* Section for remaining paragraphs */}
      <div className="mt-8">
        {paragraphs.slice(2).map((paragraph, index) => (
          <p key={index} className="mt-3">
            {paragraph}
          </p>
        ))}

        {/* List items */}
        <div className="mt-4">
          {listItems.map((item, index) => (
            <div className="mt-3 flex-align-center gap-x-2" key={index}>
              <div className="icon-box text-primary !bg-primary/20">
                <FiCheck />
              </div>
              <div>
                <h1 className="font-semibold capitalize">{item}</h1>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    ): ( <section className="   section-2 body-font overflow-hidden ">
  <div className="container px-5 py-24 mx-auto">
    <div className="lg:w-4/5 mx-auto flex flex-wrap">
      <img
        alt="ecommerce"
        className="lg:w-1/2 w-full lg:h-auto h-96 object-fill object-center rounded"
        src={aboutUs.photo_file || aboutUs.photos[0].url}
      />
      <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
      <h1 className="heading">{aboutUs.title}</h1>

        {paragraphs.map((paragraph, index) => (
          <p key={index} className="mt-3">
            {paragraph}
          </p>
        ))}
      </div>
    </div>
  </div>
</section>
     )}
   </>
  );
};

export default AboutUs;

import { useEffect, useState } from "react";
import { BlogPostsList, Pagination } from "../components/common/page-componets";
import SkeletonCard from "../components/shimmer/SkeletonCard";
import { Helmet } from "react-helmet";
const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalBlogs, setTotalBlogs] = useState(0);

  const itemsPerPage = 6;

  useEffect(() => {
    const fetchBlogs = async (page) => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(
          `https://wealthcastledubai.com/smartend/api/v1/topics/7/page/${page}/count/${itemsPerPage}`
        );
        const data = await response.json();

        if (response.ok) {
          setBlogs(data.topics);
          setTotalBlogs(data.total_count);

          // console.log(data, "from blog");
        } else {
          setError(data.msg || "Failed to fetch blogs");
        }
      } catch (error) {
        setError("An error occurred while fetching blogs");
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs(currentPage); // Fetch blogs when the page changes
  }, [currentPage]);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage); // Update the current page state when pagination changes
  };

  if (loading) {
    // Show multiple skeletons when loading
    return (
      <div className="pt-20 px-[3%] md:px-[6%] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {[...Array(itemsPerPage)].map((_, index) => (
          <SkeletonCard key={index} />
        ))}
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="pt-20 px-[3%] md:px-[6%]">
      <Helmet>
        <title>Our Blog - Wealth Castle Dubai</title>
        <meta
          name="description"
          content="Stay updated with the latest news, trends, and insights from Wealth Castle Dubai's blog. Discover informative articles about real estate, market trends, property investment, and more."
        />
        <meta
          name="keywords"
          content="blog, real estate blog, property investment, real estate trends, Wealth Castle Dubai, market insights, property news, Dubai real estate"
        />
      </Helmet>

      <BlogPostsList currentItems={blogs} />
      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={totalBlogs}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default Blog;

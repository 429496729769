import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";

const BlogDescription = () => {
  const { url_slug,id } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [readTime, setReadTime] = useState(null);

  const calculateReadingTime = (content) => {
    const wordsPerMinute = 200; // Average reading speed
    const textLength = content.split(/\s+/).length; // Split by words
    return Math.ceil(textLength / wordsPerMinute); // Calculate minutes
  };

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(`https://wealthcastledubai.com/smartend/api/v1/topicnew/${url_slug}`);
        const blogData = response.data.topic[0]; // Accessing the blog data from the API response
        setBlog(blogData);
        setReadTime(calculateReadingTime(blogData.details));
      } catch (error) {
        setError('Error fetching blog details');
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, [id]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  const imageUrl = blog?.photo_file || "https://via.placeholder.com/720x400?text=Image+Not+Found";

  const stripHtmlTags = (htmlString) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlString;
    return tempDiv.textContent || tempDiv.innerText || "";
  };
  return (
    <div className="min-h-screen flex justify-center items-center py-16 px-4 lg:px-0 bg-light-bg dark:bg-dark-bg transition-all">
       <Helmet>
        <title>Blog - {blog?.title || "Blog Title"}</title>
        <meta
          name="description"
          content={stripHtmlTags(blog?.details) || "Product content goes here..."}
        />
        <meta
          name="keywords"
          content="properties, real estate, Wealth Castle Dubai, property listings, property search, Dubai real estate"
        />
      </Helmet>
      <div className="max-w-6xl w-full flex flex-col lg:flex-row lg:gap-8">
        {/* Main Content */}
        <div className="lg:w-2/3 mx-auto  p-6 lg:p-8 ">
          <h1 className="text-2xl lg:text-3xl font-bold mb-4 break-words text-dark-heading dark:text-light-heading">
            {blog?.title || "Blog Title"}
          </h1>
          <img
            src={imageUrl}
            alt="Blog"
            className="w-full h-[200px] md:h-[300px] lg:h-[400px] rounded-lg object-fill mb-6"
          />
          <div className="flex justify-between text-gray-600 dark:text-gray-300 text-sm mb-6">
            <span>Published: {blog?.date ? new Date(blog.date).toLocaleDateString() : "N/A"}</span>
            <span>Time to read: {readTime || "N/A"} Minute(s)</span>
          </div>
          <div
            className="text-gray-700 dark:text-gray-300 leading-relaxed"
            dangerouslySetInnerHTML={{ __html: blog?.details || "Blog content goes here..." }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default BlogDescription;

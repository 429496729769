import React, { useEffect, useState } from 'react';
import SingleFaq from './SingleFaq';

const FaqsList = () => {
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch FAQs data from the API
    const fetchFaqs = async () => {
      try {
        const response = await fetch('https://wealthcastledubai.com/smartend/api/v1/topics/10/page/1/count/10');
        const data = await response.json();

        if (response.ok) {
          setFaqs(data.topics);
          // console.log(data)
        } else {
          setError(data.msg || 'Failed to fetch FAQs');
        }
      } catch (error) {
        setError('An error occurred while fetching FAQs');
      } finally {
        setLoading(false);
      }
    };

    fetchFaqs();
  }, []);

  if (loading) {
    return <div>Loading FAQs...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      {faqs.map((faq) => (
        <SingleFaq {...faq} key={faq.id} />
      ))}
    </div>
  );
};

export default FaqsList;

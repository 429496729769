import { useState, useEffect } from "react";
import { BiBuildings, BiMap, BiMoney } from "react-icons/bi";
import { useNavigate, useLocation } from "react-router-dom"; // Import useLocation

const Filters = () => {
  const [locations, setLocations] = useState([]);
  const [categories, setCategories] = useState([]);
  const [location, setLocation] = useState("");
  const [category, setCategory] = useState("");
  const [priceRange, setPriceRange] = useState("");

  const navigate = useNavigate();
  const { search } = useLocation();

  // Fetch locations and categories from APIs
  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const res = await fetch("https://wealthcastledubai.com/smartend/api/v1/locations");
        const locationData = await res.json();
        setLocations(locationData.details || []); // Ensure we handle empty response
      } catch (error) {
        console.error("Error fetching locations:", error);
      }
    };

    const fetchCategories = async () => {
      try {
        const res = await fetch("https://wealthcastledubai.com/smartend/api/v1/categories/8");
        const categoryData = await res.json();
        if (categoryData.categories.length > 0) {
          setCategories(categoryData.categories[0].sub_categories || []); // Ensure we handle empty response
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchLocations();
    fetchCategories();
  }, []);

  // Read query parameters from URL and set the form values
  useEffect(() => {
    const searchParams = new URLSearchParams(search);

    const selectedLocation = searchParams.get("location_id");
    const selectedCategory = searchParams.get("category");
    const selectedPrice = searchParams.get("price");

    if (selectedLocation) setLocation(selectedLocation);
    if (selectedCategory) setCategory(selectedCategory);
    if (selectedPrice) setPriceRange(selectedPrice);
  }, [search]);

  const handleSearch = () => {
    const filters = {};

    if (location && location !== "all") filters.location_id = location;
    if (priceRange && priceRange !== "all") filters.price = priceRange;
    if (category && category !== "all") filters.category = category;

    const searchParams = new URLSearchParams(filters).toString();
    navigate(`/property?${searchParams}`);
  };

  // Check if search button should be enabled when any field has value
  const isSearchDisabled = !location && !priceRange && !category;

  return (
    <div className="md:max-w-[80%] w-full mx-auto relative lg:-mt-20  md:mt-2 mt-10">
      <div className="flex-col bg-white gap-x-4 flex-center-between gap-y-4 md:gap-y-0 md:flex-row card card-shadow dark:shadow-none">
        <div className="flex-col flex-1 w-full flex-align-center gap-x-4 md:w-fit sm:flex-row gap-y-4 sm:gap-y-0">
          <div className="flex-1 w-full p-2 rounded-lg md:w-fit bg-slate-100 dark:bg-hover-color-dark card-bordered">
            <h1 className="font-bold">Location</h1>
            <div className="flex-align-center gap-x-2">
              <BiMap />
              <select
                className="w-full bg-transparent border-0 outline-none dark:bg-hover-color-dark opacity-70"
                value={location}
                onChange={(e) => setLocation(e.target.value)} // Update location state
              >
                <option value="">Select Location</option>
                <option value="all">All</option> {/* All option for locations */}
                {locations.map((loc) => (
                  <option key={loc.id} value={loc.id}>
                    {loc.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex-1 w-full p-2 rounded-lg md:w-fit bg-slate-100 dark:bg-hover-color-dark card-bordered">
            <h1 className="font-bold">Property</h1>
            <div className="flex-align-center gap-x-2">
              <BiBuildings />
              <select
                className="w-full bg-transparent border-0 outline-none dark:bg-hover-color-dark opacity-70"
                value={category}
                onChange={(e) => setCategory(e.target.value)} // Update category state
              >
                <option value="">Select Property</option>
                <option value="all">All</option> {/* All option for categories */}
                {categories.map((cat) => (
                  <option key={cat.id} value={cat.id}>
                    {cat.title}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        {/* <div className="flex-col flex-1 w-full flex-align-center gap-x-4 md:w-fit sm:flex-row gap-y-4 sm:gap-y-0">
          <div className="flex-1 w-full p-2 rounded-lg md:w-fit bg-slate-100 dark:bg-hover-color-dark card-bordered">
            <h1 className="font-bold">Price range</h1>
            <div className="flex-align-center gap-x-2">
              <BiMoney />
              <select
                className="w-full bg-transparent border-0 outline-none dark:bg-hover-color-dark opacity-70"
                value={priceRange}
                onChange={(e) => setPriceRange(e.target.value)} 
              >
                <option value="">Select Price Range</option>
                <option value="all">All</option> 
                <option value="2000">200 - 2000</option>
              </select>
            </div>
          </div>
        </div> */}

        <button
          className={`w-full btn btn-primary md:w-fit ${isSearchDisabled ? "opacity-50 cursor-not-allowed" : ""}`}
          onClick={handleSearch}
          disabled={isSearchDisabled}
        >
          Search
        </button>
      </div>
    </div>
  );
};

export default Filters;

import { useEffect, useState } from "react";

const PrivacyPolicy = () => {
  const [termsData, setTermsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch Terms & Conditions Data
  useEffect(() => {
    const fetchTermsData = async () => {
      try {
        const response = await fetch("https://wealthcastledubai.com/smartend/api/v1/topic/3");
        const data = await response.json();
        setTermsData(data.topic[0]); // Set the first topic (Terms & Conditions)
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch the data.");
        setLoading(false);
      }
    };

    fetchTermsData();
  }, []);

  if (loading) return <div className="text-center">Loading...</div>;
  if (error) return <div className="text-center text-red-500">{error}</div>;

  return (
    <div className="max-w-4xl mx-auto px-4 py-[8%]">
      <h1 className="text-3xl font-bold text-center mb-6">
        {termsData?.title || "Privacy & Policy"}
      </h1>
      <div
        className="prose prose-lg max-w-none"
        dangerouslySetInnerHTML={{ __html: termsData?.details || "" }}
      />
    </div>
  );
};

export default PrivacyPolicy;

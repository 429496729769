import { useState, useEffect } from "react";
import heroData from "../../../utils/apis/hero.json";
import { useNavigate } from "react-router-dom";
import ReusablePopup from "../../common/ReusablePopup"; // Import your popup component

const Hero = () => {
  const [homeWelcomeTopic, setHomeWelcomeTopic] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false); // State for the popup
  const navigate = useNavigate();

  const openPopup = () => {
    setIsPopupOpen(true); // Open the popup
  };

  const closePopup = () => {
    setIsPopupOpen(false); // Close the popup
  };
  const handleRedirect=()=>{
    navigate('/company'); // Redirect to home page
  }

  useEffect(() => {
    // Fetch data from the API
    fetch('https://wealthcastledubai.com/smartend/api/v1/topics/1/page/1/count/10')
      .then((response) => response.json())
      .then((data) => {
        const topics = data.topics;
        // Find the topic with id: 5 and title: "Home Welcome"
        const topic = topics.find((item) => item.id === 5);
        if (topic) {
          setHomeWelcomeTopic(topic);
        } else {
          setError('Topic not found');
        }
        setLoading(false);
      })
      .catch((error) => {
        setError('Error fetching data');
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div
        className=" relative z-0 flex-wrap  gap-12  flex-center-center"
       
      >
        {/* Skeleton Loader */}
        <div className="absolute top-0 right-0 rounded-full bg-[#04a7ff]/30 dark:bg-[#04a7ff]/50 w-72 h-72 -z-10 blur-[120px]"></div>
        <div className="flex-1 basis-[20rem] animate-pulse">
          <div className="h-10 w-3/4 bg-gray-300 rounded-md mb-4"></div>
          <div className="h-6 w-1/2 bg-gray-300 rounded-md mb-6"></div>
          <div className="h-6 w-1/2 bg-gray-300 rounded-md mb-6"></div>
          <div className="h-8 w-1/4 bg-primary rounded-md"></div>
        </div>
        <div className="flex-1 basis-[20rem] animate-pulse">
          <div className="w-full h-64 bg-gray-300 rounded-md"></div>
        </div>
      </div>
    );
  }

  if (error) return <div>{error}</div>;

  // Function to extract the first paragraph from the HTML content
  const getFirstParagraph = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
    const paragraphs = doc.querySelectorAll("p");
    return paragraphs.length > 0 ? paragraphs[0].outerHTML : ""; // Return the first paragraph
  };

  const isSmallScreen = window.innerWidth < 768; // Adjust this breakpoint as needed

  return (
    <div className="px-2 lg:-mt-[5%] relative z-0 flex-wrap min-h-screen gap-12 md:-mt-10 flex-center-center -mt-0">
      <div className="absolute top-0 right-0 rounded-full bg-[#04a7ff]/30 dark:bg-[#04a7ff]/50 w-72 h-72 -z-10 blur-[120px]"></div>
      <div className="flex-1 basis-[20rem]">
        <h1
          className="text-4xl font-bold capitalize md:text-5xl"
          dangerouslySetInnerHTML={{ __html: homeWelcomeTopic.title }}
        />
        <div className="pl-0 mt-5 text-left z-40">
          {/* Show full content on small screens, only the first paragraph on larger screens */}
          <p
            dangerouslySetInnerHTML={{
              __html: isSmallScreen
                ? homeWelcomeTopic.details // Show full details when on small screen
                : getFirstParagraph(homeWelcomeTopic.details), // Show only the first paragraph on larger screens
            }}
          />
        </div>
        {/* Read More Button visible only on medium and large screens */}
        
          <button onClick={handleRedirect} className="mt-6 btn btn-primary">
            Read More
          </button>
      
        {/* {!isSmallScreen && (
          <button onClick={openPopup} className="mt-6 btn btn-primary">
            Read More
          </button>
        )} */}
      </div>
      <div className="flex-1 basis-[18rem]">
        <img src={homeWelcomeTopic.photo_file} alt="hero" className="object-fill w-full rounded-lg"/>
      </div>

      {/* Popup for showing the full message */}
      {/* <ReusablePopup isOpen={isPopupOpen} onClose={closePopup}>
        <h2 className="text-2xl font-bold mb-4">{homeWelcomeTopic.title}</h2>
        <div
          className="opacity-75"
          dangerouslySetInnerHTML={{ __html: homeWelcomeTopic.details }}
        />
      </ReusablePopup> */}
    </div>
  );
};

export default Hero;

import React, { useState, useEffect } from 'react';
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const ResponsiveBanner = ({ interval = 3000 }) => {
  const [banners, setBanners] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [animateText, setAnimateText] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch banners from the API
  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await fetch('https://wealthcastledubai.com/smartend/api/v1/banners/1');
        const data = await response.json();
        setBanners(data.banners || []);
        setIsLoading(false); // Loading complete
      } catch (error) {
        console.error('Error fetching banners:', error);
        setIsLoading(false); // Even on error, stop loading
      }
    };

    fetchBanners();
  }, []);

  // Auto-advance banner
  useEffect(() => {
    if (banners.length === 0) return;

    setAnimateText(true);
    const cycleImages = setInterval(() => {
      handleNext();
    }, interval);

    return () => clearInterval(cycleImages);
  }, [banners, interval]);

  const handleNext = () => {
    setAnimateText(false);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % banners.length);
      setAnimateText(true);
    }, 100);
  };

  const handlePrev = () => {
    setAnimateText(false);
    setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? banners.length - 1 : prevIndex - 1
      );
      setAnimateText(true);
    }, 100);
  };

  // Skeleton loading state
  if (isLoading) {
    return (
      <div className="relative w-full h-[312px] md:h-[500px] mb-20 overflow-hidden bg-gray-200 animate-pulse">
        <div className="absolute w-full h-full bg-gray-300"></div>
        <div className="text-overlay">
          <div className="h-8 w-2/3 bg-gray-400 mx-auto rounded mb-4"></div>
          <div className="h-6 w-1/2 bg-gray-400 mx-auto rounded"></div>
        </div>
      </div>
    );
  }

  // Fallback if no banners are available
  if (!banners || banners.length === 0) {
    return <div>No banners available</div>;
  }

  return (
    <div className="lg:mt-[2%] mt-[5%] relative w-full h-[312px] md:h-[500px] mb-20 overflow-hidden">
      <style>{`
        @keyframes slideInLeft {
          0% {
            opacity: 0;
            transform: translateX(-100%);
          }
          100% {
            opacity: 1;
            transform: translateX(0);
          }
        }

        .animate-slide-in-left {
          animation: slideInLeft 0.8s ease-out;
        }

        .text-overlay {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: white;
          text-align: center;
          max-width: 80%;
        }

        .nav-buttons {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          color: white;
          font-size: 2rem;
          cursor: pointer;
          z-index: 10;
        }

        .prev-button {
          left: 10px;
        }

        .next-button {
          right: 10px;
        }

        .responsive-banner img {
          width: 100%;
          height: 100%;
          object-fit: contain; /* Ensures the image fits the container without being cropped or distorted */
        }

        @media (max-width: 1279px) {
          .responsive-banner img {
            object-fit: contain; /* On smaller screens, ensure full visibility without distortion */
          }

          .text-overlay {
            font-size: 1.2rem;
          }
        }
      `}</style>

      <img
        src={banners[currentIndex].file}
        alt={banners[currentIndex].title}
        className="w-full h-full responsive-banner rounded-md"
      />

      <div className="text-overlay">
        <h1 className={`text-2xl md:text-4xl font-bold ${animateText ? 'animate-slide-in-left' : ''}`}>
          {banners[currentIndex].title}
        </h1>
        <p className={`text-lg md:text-xl mt-4 ${animateText ? 'animate-slide-in-left' : ''}`}>
          {banners[currentIndex].details}
        </p>
      </div>

      <div className="nav-buttons prev-button" onClick={handlePrev}>
        <FaAngleLeft className="text-white" />
      </div>
      <div className="nav-buttons next-button" onClick={handleNext}>
        <FaAngleRight className="text-white" />
      </div>
    </div>
  );
};

export default ResponsiveBanner;

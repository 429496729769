import React, { useEffect, useState } from "react";
import ReusablePopup from "./common/ReusablePopup";

const CeosMsg = () => {
  const [ceoMessage, setCeoMessage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://wealthcastledubai.com/smartend/api/v1/topic/141"
        );
        const data = await response.json();
        if (data && data.topic && data.topic.length > 0) {
          setCeoMessage(data.topic[0]);
        } else {
          setError("Data not found");
        }
        setLoading(false);
      } catch (err) {
        setError("Error fetching data");
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  if (loading) {
    return <div className="text-center text-gray-600">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500">{error}</div>;
  }

  const getFirstTwoParagraphs = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
    const paragraphs = doc.querySelectorAll("p");
    return Array.from(paragraphs)
      .slice(0, 2)
      .map((p) => p.outerHTML)
      .join("");
  };

  const isSmallScreen = window.innerWidth < 768; // Adjust this breakpoint as needed

  const isMediumScreen = (windowWidth >= 768 && windowWidth <= 884) || (windowWidth >= 1024 && windowWidth <= 1104);


  return (
    <>
     {!isMediumScreen? 
     (
      <section className=" section-1  light pb-14 md:pb-24 bg-white dark:bg-main-dark text-zinc-900 dark:text-white">
  <div className=" px-4 mt-12 ">
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center"> {/* Adjusted grid with gap and center alignment */}
      
      {/* Content Section */}
      <div className="order-2  md:order-1 flex flex-col justify-center text-center md:text-left lg:-mt-[15%] -mt-0">
      {/* <h1 className="sub-heading"> {ceoMessage.title || "CEO's Message"}</h1> */}
       {/* Text center on mobile, left on larger screens */}
        <h1 className="text-2xl md:text-4xl font-bold mb-4">
          {ceoMessage.title || "CEO's Message"}
        </h1>
        <div
  className={`opacity-75 mb-4 ${isSmallScreen ? 'text-left' : ''}`} // Add 'text-left' for small screens
  dangerouslySetInnerHTML={{
    __html: isSmallScreen
      ? ceoMessage.details // Show full details on small screens
      : getFirstTwoParagraphs(ceoMessage.details), // Show only first two paragraphs on larger screens
  }}
/>

        {/* Read More Button only visible on medium and large screens */}
        {!isSmallScreen && (
          <button
            className="bg-primary text-white rounded-md px-4 py-2 w-fit mt-2 hidden lg:inline-block"  // Inline-block for button
            onClick={openPopup}
          >
            Read More
          </button>
        )}
      </div>

      {/* Image Section */}
      <div className="order-1 md:order-2 flex flex-col items-center justify-center space-y-4"> {/* Flex column for image and name below */}
        <img
          src={ceoMessage.photo_file}
          alt="CEO"
          className="w-80 h-80 lg:w-96 lg:h-96 object-cover aspect-square rounded-lg shadow-lg"  // Updated object-cover for better fit
        />
        
        {/* Name and Designation below the image */}
        <div className="text-center">
          <h1 className="text-xl font-semibold text-black dark:text-white">
            {/* Replace with CEO's name */}
            { "Mr. Mohammed Fayaz"}
          </h1>
          <p className="text-gray-600 dark:text-gray-300">
            {/* Replace with designation */}
            {"Founder & CEO"}
          </p>
        </div>
      </div>

    </div>
  </div>

  <ReusablePopup isOpen={isPopupOpen} onClose={closePopup}>
    <h2 className="text-2xl font-bold mb-4">{ceoMessage.title}</h2>
    <div
      className="opacity-75"
      dangerouslySetInnerHTML={{ __html: ceoMessage.details }}
    />
  </ReusablePopup>
</section>


      )
      :
     ( <section className="   section-2 body-font overflow-hidden ">
  <div className="container px-5 py-24 mx-auto">
    <div className="lg:w-4/5 mx-auto flex flex-wrap">
      <img
        alt="ecommerce"
        className="lg:w-1/2 w-full lg:h-auto h-96 object-fill object-center rounded"
        src={ceoMessage.photo_file}
      />
      <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
        <h1 className="text-2xl md:text-4xl font-bold mb-2">
          {ceoMessage.title || "CEO's Message"}
        </h1>

        <div
          className="opacity-75 mb-4 word"
          dangerouslySetInnerHTML={{
            __html: ceoMessage.details,
          }}
        />
      </div>
    </div>
  </div>
</section>
     )

     }
    </>
  );
};

export default CeosMsg;

import { BiBed, BiMap, BiMapAlt } from "react-icons/bi";
import { Link } from "react-router-dom";

const SingleProductCard = ({
  id,
  title,
  location,
  distance,
  purpose,
  number_of_beds,
  number_of_bathrooms,
  fields,
  photo_file,
  Joined_categories,
  photos,
  basis,
  href,
  url_slug
}) => {

  // Function to convert strings to a slug (lowercase and replace spaces with hyphens)
  const convertToSlug = (text) => {
    return text.toLowerCase().replace(/\s+/g, '-');
  };

  const slugifiedTitle = convertToSlug(title); // Convert title to slug
  const slugifiedCategory = convertToSlug(Joined_categories[0]?.url_slug); // Convert category slug to lowercase

  // Function to format the number as "1,2,70,000"
  const formatPrice = (num) => {
    const str = num.toString();
    const n = str.length;

    if (n > 3) {
      // First comma is placed before the last three digits
      const lastThree = str.substring(n - 3);

      // Remaining digits are processed in groups of two
      const remaining = str.substring(0, n - 3);
      
      // Add commas every 2 digits from the right side for the remaining part
      const formattedRemaining = remaining.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
      
      return formattedRemaining + "," + lastThree;
    }
    
    return str; // If number is less than 1000, return as is
  };

  // Function to extract the price from the fields array and format it
  const getPrice = (fields) => {
    const priceField = fields.find(field => field.title === "Price");
    const price = priceField ? priceField.value : "N/A";
    
    // Check if price is a valid number before formatting
    return price !== "N/A" && !isNaN(price) ? formatPrice(price) : price;
  };

  const price = getPrice(fields);

  return (
    <div className={`shadow-light dark:border-card-dark border rounded-lg overflow-hidden relative group basis-[18rem]`}>
      <div className="group !opacity-100 overflow-hidden relative">
        {/* Link with slugified title and category */}
        <Link to={`${slugifiedCategory}/${url_slug}`} className="!opacity-100">
          <img
            src={photo_file || Joined_categories[0].photo || "https://wealthcastledubai.com/smartend/uploads/topics/default.png"}
            alt={title}
            className="w-full h-fit md:h-[250px] object-cover group-hover:scale-125 transition-a"
          />
        </Link>
        <div className="absolute bottom-0 left-0 w-full px-2 py-2 transition-transform bg-gradient-to-t from-black/80 sm:translate-y-10 group-hover:translate-y-0 to-transparent">
          <div className="text-white flex-align-center gap-x-2">
            <BiMap />
            <p>{location && typeof location === 'string' ? location : location?.name || 'Unknown Location'}</p>
          </div>
        </div>
      </div>
      <div className="p-3">
        {/* Title with slugified link */}
        <Link to={`${slugifiedCategory}/${url_slug}`}  className="group-hover:text-primary transition-a">
          <h1 className="text-lg font-bold capitalize">{title && typeof title === 'string' ? title : 'Unknown Property'}</h1>
        </Link>
        <div className="flex justify-between mt-3">
          <div className="flex-align-center gap-x-2">
            <div className="icon-box !w-7 !h-7 bg-primary/20 hover:!bg-primary/40 text-primary">
              <BiBed />
            </div>
            <p className="text-sm">{Joined_categories[0]?.title || 'Unknown Category'}</p>
          </div>
          <div className="flex-align-center gap-x-2">
            <div className="icon-box !w-7 !h-7 bg-primary/20 hover:!bg-primary/40 text-primary">
              <BiMapAlt />
            </div>
            <p>{location && typeof location === 'string' ? location : location?.name || 'Unknown Location'}</p>
          </div>
        </div>

        <div className="mt-4 flex-center-between">
          <h1 className="text-md font-semibold text-primary"> <span className="text-black dark:text-white">Starting From </span> &nbsp;AED {price}</h1>
          <Link to={`${slugifiedCategory}/${url_slug}`}  className="btn btn-secondary">details</Link>
        </div>
      </div>
    </div>
  );
};

export default SingleProductCard;

import { useSelector } from "react-redux";
import { dataStore } from "../../../features/dataSlice";
import SingleBlogsCard from "./SingleBlogsCard";

const BlogPostsList = ({ currentItems }) => {
  // console.log(currentItems, "from blog post list");

  return (
    <div className="flex flex-wrap gap-4">
      {currentItems.map((blog) => (
        <SingleBlogsCard key={blog.id} {...blog} />
      ))}
    </div>
  );
};

export default BlogPostsList;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { uiStore } from "../features/uiSlice";

const ServicesWithLogo = () => {
  const navigate = useNavigate();
  const { darkMode } = useSelector(uiStore);

  const [apiData, setApiData] = useState(null); // State to store the API data
  const handleReadMore = () => {
    navigate("/services"); // Redirect to the /services page
  };

  useEffect(() => {
    // Fetch the data from the API
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://wealthcastledubai.com/smartend/api/v1/topic/142"
        );
        const data = await response.json();
        // Store the topic from the API response
        if (data && data.topic && data.topic.length > 0) {
          setApiData(data.topic[0]); // Take the first topic in the array
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <section className="py-10 flex items-center justify-center  bg-white dark:bg-main-dark text-zinc-900 dark:text-white overflow-hidden my-8">
      <div className="container mx-auto text-center px-4">
        {/* Logo Section */}
        <div className="relative flex justify-center items-center mb-5">
          <div className="absolute top-0 left-0 right-0 bottom-0 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 opacity-20 rounded-full -z-10" />
          <img
            src={
              darkMode
                ? "/images/LOGOMaster.png"
                : "/images/LOGOMaster_black_only_text.png"
            }
            alt="Company Logo"
            className="w-80 h-auto object-fill"
          />
        </div>

        {/* Dynamic Content from API */}
        {apiData && (
          <>
            <h2 className="text-2xl md:text-4xl font-bold mb-4">
              {apiData.title || "Welcome to Wealth Castle Real Estate"}
            </h2>
            {/* Parsing HTML content from 'details' */}
            <div
              className="text-lg md:text-xl text-gray-600 dark:text-gray-400 max-w-xl mx-auto"
              dangerouslySetInnerHTML={{ __html: apiData.details }}
            />
          </>
        )}

        {/* Call to Action */}
        <div className="mt-8">
          <button
            onClick={handleReadMore}
            className="bg-primary py-2 px-6 rounded-md text-lg font-medium transition-all shadow-lg"
          >
            Explore Our Services
          </button>
        </div>
      </div>
    </section>
  );
};

export default ServicesWithLogo;

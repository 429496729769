import { useRef, useState, useEffect, useCallback } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import categoriesData from "../../../utils/apis/categoriesData.json";
// import { Button } from "flowbite-react";
import axios from "axios";

const Categories = () => {
  const categoryContainer = useRef(null);
  const [data, setData] = useState([]);
  const [topicData, setTopicData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Fetch categories data from the API
        const { data: categoriesResponse } = await axios.get(
          "https://wealthcastledubai.com/smartend/api/v1/categories/8"
        );

        // Set categories data, defaulting to an empty array if not found
        setData(categoriesResponse.categories[0]?.sub_categories || []);

        // Fetch topic data
        const { data: topicResponse } = await axios.get(
          "https://wealthcastledubai.com/smartend/api/v1/topic/127"
        );
        const topic = topicResponse.topic[0];
        setTopicData(topic || {});
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const scrollContainer = useCallback((direction) => {
    const scrollAmount = 200;
    if (categoryContainer.current) {
      categoryContainer.current.scrollLeft += direction === "right"
        ? scrollAmount
        : -scrollAmount;
    }
  }, []);

  const handleRedirect = () => navigate("/property");

  if (loading) {
    return (
      <div className="pt-10 pb-16">
        <div className="grid grid-cols-1 gap-16 sm:grid-cols-2 md:grid-cols-4">
          {/* Skeleton Loading UI */}
          <SkeletonLoader />
        </div>
      </div>
    );
  }

  const { heading, buttonText } = categoriesData;

  return (
    <div className="pt-10 pb-16">
      <div className="grid grid-cols-1 gap-16 sm:grid-cols-2 md:grid-cols-4">
        {/* Text Section */}
        <div className="md:col-span-1">
          <h1 className="sub-heading">{heading}</h1>
          <h1 className="heading">{topicData?.title}</h1>
          <div
            className="mt-2"
            dangerouslySetInnerHTML={{ __html: topicData?.details }}
          />
          <button onClick={handleRedirect} className="mt-4 btn btn-primary">
            {buttonText}
          </button>
        </div>

        {/* Categories Section */}
        <div className="md:col-span-3">
          <div className="justify-end flex-align-center gap-x-3">
            <ScrollButton direction="left" onClick={() => scrollContainer("left")} />
            <ScrollButton direction="right" onClick={() => scrollContainer("right")} />
          </div>

          <div
            className="gap-3 mt-4 overflow-auto flex-align-center scroll-smooth hide-scrollbar"
            ref={categoryContainer}
          >
            {data.map(({ id, title, photo }) => (
              <CategoryCard
                key={id}
                id={id}
                title={title}
                photo={photo}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

// Component for Scroll Button
const ScrollButton = ({ direction, onClick }) => (
  <button
    className={`btn btn-secondary !p-2`}
    onClick={onClick}
  >
    {direction === "left" ? <FiChevronLeft /> : <FiChevronRight />}
  </button>
);

// Component for each Category Card
const CategoryCard = ({ id, title, photo }) => (
  <div
    className="relative flex-shrink-0 w-[300px] group rounded-lg overflow-hidden"
  >
    <Link to={`/property?category=${id}`}>
      <img
        src={photo || "https://wealthcastledubai.com/smartend/uploads/topics/default.png"}
        alt={title}
        className="w-full h-[300px] object-cover group-hover:scale-125 transition-a"
      />
    </Link>
    <div className="absolute bottom-0 left-0 w-full px-2 py-2 bg-gradient-to-t from-black/80 text-slate-100 to-transparent">
      <h1 className="text-lg font-semibold">{title}</h1>
    </div>
  </div>
);

// Component for Skeleton Loader (while data is loading)
const SkeletonLoader = () => (
  <>
    <div className="md:col-span-1">
      <div className="w-full h-8 bg-gray-300 rounded-md mb-4 animate-pulse"></div>
      <div className="w-full h-6 bg-gray-300 rounded-md mb-2 animate-pulse"></div>
      <div className="w-full h-6 bg-gray-300 rounded-md mb-2 animate-pulse"></div>
      <div className="w-1/2 h-6 bg-gray-300 rounded-md animate-pulse"></div>
      <div className="mt-4 w-32 h-10 bg-gray-300 rounded-md animate-pulse"></div>
    </div>
    <div className="md:col-span-3">
      <div className="flex justify-end gap-x-3 mb-4">
        <div className="w-10 h-10 bg-gray-300 rounded-full animate-pulse"></div>
        <div className="w-10 h-10 bg-gray-300 rounded-full animate-pulse"></div>
      </div>
      <div className="gap-3 mt-4 flex-align-center">
        {[1, 2, 3, 4].map((_, index) => (
          <div
            key={index}
            className="w-[300px] h-[300px] bg-gray-300 rounded-lg animate-pulse"
          ></div>
        ))}
      </div>
    </div>
  </>
);

export default Categories;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  FaPhoneAlt,
  FaEnvelope,
  FaMapMarkerAlt,
  FaClock,
} from "react-icons/fa"; 

const Form = () => {
  const { url_slug } = useParams();

  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactSubject, setContactSubject] = useState("");
  const [contactMessage, setContactMessage] = useState(
    "Hi, I would like to get more information about this property."
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [contactInfo, setContactInfo] = useState({}); 
  const [apiError, setApiError] = useState(null); 

  const formatUrlSlug = (slug) => {
    return slug
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  useEffect(() => {
    if (url_slug) {
      setContactSubject(formatUrlSlug(url_slug));
    }
  }, [url_slug]);

  useEffect(() => {
    const fetchContactInfo = async () => {
      try {
        const response = await axios.get(
          "https://wealthcastledubai.com/smartend/api/v1/website/contacts"
        );
        setContactInfo(response.data.details); 
      } catch (err) {
        setApiError(
          "Error fetching contact information. Please try again later."
        );
      }
    };

    fetchContactInfo();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

   
    if (contactPhone.length < 10) {
      setError("Phone number must be at least 10 digits.");
      setLoading(false);
      return;
    }

    const payload = {
      api_key: "402784613679330", 
      topic_id: 8, 
      contact_name: contactName,
      contact_email: contactEmail,
      contact_phone: contactPhone,
      contact_subject: contactSubject,
      contact_message: contactMessage,
    };

    try {
      await axios.post(
        "https://wealthcastledubai.com/smartend/api/v1/contact",
        payload
      );
      setSuccess("Your message has been sent successfully!");
    
      setContactName("");
      setContactEmail("");
      setContactPhone("");
      setContactSubject("");
      setContactMessage(
        "Hi, I would like to get more information about this property."
      ); 
    } catch (err) {
      setError("Error sending message. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/\D/g, "");
    setContactPhone(numericValue);
  };

  return (
    <div className="flex flex-col md:flex-row">
      <form
        onSubmit={handleSubmit}
        className="flex-1 w-full md:w-[70%] pt-10 pb-8"
      >
        <div className="flex-col gap-4 flex">
          <div className="flex-1 w-full">
            <p>Name</p>
            <input
              type="text"
              className="w-full input"
              placeholder="Name..."
              value={contactName}
              onChange={(e) => setContactName(e.target.value)}
              required
            />
          </div>
          <div className="flex-1 w-full">
            <p>Phone Number</p>
            <input
              type="tel"
              className="w-full input"
              placeholder="Phone Number..."
              value={contactPhone}
              onChange={handlePhoneChange}
              required
            />
          </div>
        </div>
        <div className="mt-4">
          <p>Email Address</p>
          <input
            type="email"
            className="w-full input"
            placeholder="Email Address..."
            value={contactEmail}
            onChange={(e) => setContactEmail(e.target.value)}
            required
          />
        </div>
        <div className="mt-4">
          <p>Project Name</p>
          <input
            type="text"
            className="w-full input"
            placeholder="Project Name..."
            value={contactSubject}
            onChange={(e) => setContactSubject(e.target.value)}
            required
          />
        </div>
        <div className="mt-4">
          <p>Message</p>
          <textarea
            rows={4}
            className="w-full input"
            placeholder="Message..."
            value={contactMessage}
            onChange={(e) => setContactMessage(e.target.value)}
            required
          ></textarea>
        </div>
        <div className="mt-4 flex justify-center">
          <button className="btn btn-primary" disabled={loading}>
            {loading ? "Submitting..." : "Submit"}
          </button>
        </div>
        {error && <p className="text-red-500">{error}</p>}
        {success && <p className="text-green-500">{success}</p>}
      </form>

      <div className="flex-1 w-full md:w-[30%] p-4 md:p-10">
  <h2 className="text-xl font-bold">Contact Information</h2>
  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
    {/* Phone */}
    <div className="flex items-center" style={{ alignItems: 'flex-start' }}>
      <div className="flex items-center lg:w-10 md:w-5 w-8 h-8 justify-center mr-2"> {/* Adjusted margin */}
        <FaPhoneAlt className="text-xl w-[18px] text-primary" />
      </div>
      <p>
        <span className="font-bold">Phone:</span><br /> 
        {contactInfo.mobile || "N/A"}
      </p>
    </div>
    {/* Email */}
    <div className="flex items-center" style={{ alignItems: 'flex-start' }}>
      <div className="flex items-center lg:w-10 md:w-20 w-8 h-8 justify-center mr-2"> {/* Adjusted margin */}
        <FaEnvelope className="text-xl  w-[20px] mb-1 text-primary" />
      </div>
      <p>
        <span className="font-bold">Email:</span><br /> 
        {contactInfo.email || "N/A"}
      </p>
    </div>
    {/* Office Hours */}
    <div className="flex items-center" style={{ alignItems: 'flex-start' }}>
      <div className="flex items-center lg:w-14 w-[42px] mr-2 h-8 justify-center text-center"> {/* Adjusted margin */}
        <FaClock className="text-xl w-[20px] text-primary" />
      </div>
      <p>
        <span className="font-bold">Office Hours:</span><br /> 
        {contactInfo.working_time || "N/A"}
      </p>
    </div>
    {/* Address */}
    <div className="flex items-center" style={{ alignItems: 'flex-start' }}>
      <div className="flex items-center lg:w-20 md:w-16 w-16 mr-2 h-8 justify-center"> {/* Adjusted margin */}
        <FaMapMarkerAlt className="text-xl w-[20px] text-primary" />
      </div>
      <p>
        <span className="font-bold">Address:</span><br /> 
        {apiError ? "Loading..." : contactInfo.address || "N/A"}
      </p>
    </div>
  </div>
  <div className="mt-4">
    <h3 className="font-bold">Location:</h3>
    <div className="w-full h-48">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.5142201032677!2d55.291135399999995!3d25.1858756!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6900ad3b7781%3A0xde446e51031f066a!2sDAMAC%20Paramount%20Tower%20B!5e0!3m2!1sen!2sin!4v1727882227367!5m2!1sen!2sin"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        title="Google Map"
      ></iframe>
    </div>
  </div>
  {apiError && <p className="text-red-500">{apiError}</p>} {/* Display API error if exists */}
</div>


    </div>
  );
};

export default Form;

import React, { useEffect, useState } from "react";
import { Pagination } from "../components/common/page-componets";
import { PropertyList } from "../components/property";
import axios from "axios";
import { useLocation } from "react-router-dom";
import SkeletonCard from "../components/shimmer/SkeletonCard";
import { Filters } from "../components/home/home-1";
import NoDataFound from "../components/common/NoDataFound";
import { Helmet } from "react-helmet"; 

const Property = () => {
  const [propertyData, setPropertyData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const itemsPerPage = 6;

  const location = useLocation();

  // Get query parameters
  const searchParams = new URLSearchParams(location.search);
  const location_id = searchParams.get("location_id");
  const price = searchParams.get("price");
  const category = searchParams.get("category");

  useEffect(() => {
    const fetchProperties = async () => {
      setLoading(true);
      try {
        // Prepare the request body based on the provided parameters
        const requestBody = {};
        if (location_id) requestBody.location_id = location_id;
        if (price) requestBody.price = price;
        if (category) requestBody.category = category;

        // Hit the product filter API with dynamic params
        const response = await axios.post(
          `https://wealthcastledubai.com/smartend/api/v1/product-filter`,
          requestBody
        );

        // Assuming the API response structure based on the provided response
        setPropertyData(response.data.topics || []);
      } catch (error) {
        console.error("Error fetching properties:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProperties();
  }, [location_id, price, category]);

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const indexOfLastProperty = currentPage * itemsPerPage;
  const indexOfFirstProperty = indexOfLastProperty - itemsPerPage;
  const currentProperties = propertyData.slice(indexOfFirstProperty, indexOfLastProperty);

  return (
    <div className="pt-16 px-[3%] md:px-[6%]">
      <Helmet>
        <title>Our Properties - Wealth Castle Dubai</title>
        <meta
          name="description"
          content="Explore a wide range of properties at Wealth Castle Dubai. Browse through various locations, categories, and price ranges to find the perfect property that suits your needs."
        />
        <meta
          name="keywords"
          content="properties, real estate, Wealth Castle Dubai, property listings, property search, Dubai real estate"
        />
      </Helmet>
      
      {loading ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {[...Array(8)].map((_, index) => (
            <SkeletonCard key={index} />
          ))}
        </div>
      ) : propertyData.length < 1 ? ( // Check if no data is available
        <div className="text-center text-lg mt-10">
          <NoDataFound/>
        </div>
      ) : (
        <>
          <div className="mt-[8%] mb-[5%]">
            <Filters />
          </div>
          <PropertyList properties={currentProperties} />
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={propertyData.length}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </>
      )}
    </div>
  );
};

export default Property;

import React, { useEffect, useState } from "react";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";
import axios from "axios";

const Team = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [sectionTitle, setSectionTitle] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        const response = await axios.get(
          "https://wealthcastledubai.com/smartend/api/v1/topics/12/page/1/count/8"
        );
        setTeamMembers(response.data.topics);
        setSectionTitle(response.data.section_title);
      } catch (err) {
        setError("Error fetching team members data");
      } finally {
        setLoading(false);
      }
    };

    fetchTeamData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="pt-16 px-[3%] md:px-[6%]">
      <h1 className="mx-auto sub-heading">{sectionTitle}</h1>
      <div className="grid grid-cols-1 gap-3 mt-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {teamMembers.map((member) => (
          <div className="mb-24" key={member.id}>
            <div className="h-[250px] w-full">
              <img
                src={member.photo_file}
                alt={member.title}
                className="object-cover w-full h-full rounded-lg"
              />
            </div>
            <div
              className="name-and-position flex flex-col justify-center items-center relative z-10 p-2 mx-auto -mt-10 text-center bg-white rounded-lg shadow-md dark:bg-dark-light"
              style={{
                minHeight: "120px",
                width: "80%",
                minWidth: "200px",
                maxWidth: "250px",
              }}
            >
              <h1 className="text-lg font-semibold">{member.title}</h1>
              
              {member.fields.length > 0 && (
                <p>{member.fields[0].value}</p>
              )}
              {/* Optional social links */}
              {/* <div className="mt-3 flex-center-center gap-x-3">
                <Link className="hover:text-primary transition-a">
                  <FaFacebook />
                </Link>
                <Link className="hover:text-primary transition-a">
                  <FaInstagram />
                </Link>
                <Link className="hover:text-primary transition-a">
                  <FaTwitter />
                </Link>
                <Link className="hover:text-primary transition-a">
                  <FaLinkedin />
                </Link>
              </div> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Team;
